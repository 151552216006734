<template>
  <div class="w-full">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-3 justify-center">
      <div class="bg-white p-5 rounded-lg text-black col-span-1 lg:col-span-3">
        <div v-if="marketing_credit || marketing_credit == 0" class="flex justify-between">
          <div>
            <span>{{ $t('business_marketing.available_credit_balance') }}: <span class="mx-5 text-xl text-success">{{ marketing_credit.toLocaleString() }} {{ $t('SAR') }}</span></span>
          </div>
          <div>
            <vs-button color="success" type="border" icon="chat" @click="rechargeWhatsapp">
              {{ $t('business_marketing.for_recharge_contact_us') }}
            </vs-button>
          </div>
        </div>
        <div v-else>
          <vs-alert :active="inputValid" color="danger" icon="new_releases" >
            {{ $t('business_marketing.please_relogin') }}
          </vs-alert>
        </div>
      </div>
      <div v-for="marketing_package in marketing_packages" :key="marketing_package.id" class="bg-white rounded-lg text-black" :class="{'is-recommended': marketing_package.is_recommended}">
        <div class="h-full flex flex-col space-y-5 justify-between">
          <div>
            <div>
              <div class="p-3 text-center text-white rounded-t-md" :class="{'bg-primary' : marketing_package.is_recommended}">
                {{marketing_package.is_recommended ? $t('business_marketing.is_recommended') : '.'}}
              </div>
            </div>

            <div class="p-10">

              <div class="text-center">
                <span class="text-3xl">
                  {{$i18n.locale == 'en' ? marketing_package.title_en : marketing_package.title_ar}}
                </span>
              </div>
              
              <hr class="w-full my-5" style="height: 1px; opacity: 0.2">
      
              <div class="flex justify-center">
                <div class="py-3 text-center w-64 relative">
                  <div class="absolute top-0 left-0">
                    <span class="text-md" style="text-decoration: line-through; color: gray;">{{marketing_package.old_price.toLocaleString()}}</span>
                  </div>
                  <div class="text-center">
                    <span class="text-5xl text-danger">
                      {{marketing_package.price.toLocaleString()}} {{ $t('SAR') }}
                    </span>
                  </div>
                  <div class="absolute bottom-0 right-0">
                    <span class="text-md">{{ $t('business_marketing.limied_offer') }}</span>
                  </div>
                </div>
              </div>
      
              <hr class="w-full my-5" style="height: 1px; opacity: 0.2">
      
              <div class="mx-5">
                <ul>
                  <li class="my-3" v-for="(including, index) in marketing_package.includings" :key="`includings-for-map-${marketing_package.id}-${index}`">
                    <div class="flex space-x-2">
                      <div>
                        <svg class="w-6 h-6 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                      </div>
                      <div class="mt-1">
                        {{$i18n.locale == 'en' ? including.title_en : including.title_ar}}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div class="p-5 pt-0" v-if="hasBalanceToRequest">
            <hr class="w-full my-5 mt-0" style="height: 1px; opacity: 0.2">
            <div class="flex justify-center">
              <vs-button class="mx-2 w-3/4 lg:w-1/2" @click="selectPackage(marketing_package)">{{ $t('business_marketing.choose_package') }}</vs-button>
            </div>
          </div>
          <div class="p-5 pt-0" v-else>
            <hr class="w-full my-5 mt-0" style="height: 1px; opacity: 0.2">
            <div class="flex justify-center">
              <vs-button disabled class="mx-2 w-3/4 lg:w-1/2">{{ $t('business_marketing.can_not_buy_package') }}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-prompt
      @cancel="selectedPackage = null"
      @accept="acceptAlert"
      :active.sync="buyConfirmation"
      title=""
      :accept-text="$t('business_marketing.confirmation_prompt.accept')"
      :cancel-text="$t('business_marketing.confirmation_prompt.cancel')"
      >
       <div v-if="selectedPackage">
          <h4>{{ $t('business_marketing.confirmation_prompt.are_you_sure') }}</h4>
          <div class="my-3">
            <span>{{ $t('business_marketing.confirmation_prompt.package_name') }}: {{$i18n.locale == 'en' ? selectedPackage.title_en : selectedPackage.title_ar}}</span>
          </div>
          <div class="my-3">
            <span>{{ $t('business_marketing.confirmation_prompt.current_balance') }}: {{ marketing_credit.toLocaleString() }} {{ $t('SAR') }}</span>
          </div>
          <div class="my-3">
            <span>{{ $t('business_marketing.confirmation_prompt.balance_after_request') }}: {{ (marketing_credit - this.selectedPackage.price).toLocaleString() }} {{ $t('SAR') }}</span>
          </div>
          <hr class="w-full my-5" style="height: 1px; opacity: 0.2">
          <div class="my-3 font-light">
            <span>{{ $t('business_marketing.confirmation_prompt.confirmation_note') }}</span>
          </div>
       </div>
     </vs-prompt>
 </div>
</template>

<script>


export default {
  components: {},
  data () {
    return {
      buyConfirmation: false,
      selectedPackage: null,
      marketing_packages: [
        {
          id: 1,
          title_en: "first package",
          title_ar: "الباقة الأولى",
          price: 575,
          old_price: 1150,
          is_recommended: false,
          includings: [
            {
              title_ar: "اعلى منشور لمدة 10 أيام",
              title_en: "Top listing for 10 Days"
            },
            {
              title_ar: "منشور انستقرام مع تاق",
              title_en: "Instagram post with tag"
            },
            {
              title_ar: "ستوري انستقرام مع تاق",
              title_en: "Instagram story with tag"
            },
            {
              title_ar: "3-1 حجز متوقع",
              title_en: "1-3 expected bookings"
            }
          ]
        },
        {
          id: 2,
          title_en: "second package",
          title_ar: "الباقة الثانية",
          price: 3450,
          old_price: 5750,
          is_recommended: true,
          includings: [
            {
              title_ar: "اعلى منشور لمدة 15 يوم",
              title_en: "Top listing for 10 Days"
            },
            {
              title_ar: "منشور انستقرام مع تاق",
              title_en: "Instagram post with tag"
            },
            {
              title_ar: "ستوري انستقرام مع تاق",
              title_en: "Instagram story with tag"
            },
            {
              title_ar: "إعلان تنبيهات الجوال للمستخدمين",
              title_en: "Push notification in app"
            },
            {
              title_ar: "إعلان عن طريق البريد الالكتروني",
              title_en: "Email advertisement"
            },
            {
              title_ar: "18-6 حجز متوقع",
              title_en: "6-18 expected bookings"
            }
          ]
        },
        {
          id: 3,
          title_en: "third package",
          title_ar: "الباقة الثالثة",
          price: 5750,
          old_price: 11500,
          is_recommended: false,
          includings: [
            {
              title_ar: "اعلى منشور لمدة 15 يوم",
              title_en: "Top listing for 10 Days"
            },
            {
              title_ar: "منشور انستقرام مع تاق",
              title_en: "Instagram post with tag"
            },
            {
              title_ar: "ستوري انستقرام مع تاق",
              title_en: "Instagram story with tag"
            },
            {
              title_ar: "إعلان تنبيهات الجوال للمستخدمين",
              title_en: "Push notification in app"
            },
            {
              title_ar: "فيديو تيكتوك",
              title_en: "Tiktok video"
            },
            {
              title_ar: "انتنسف بروموشن",
              title_en: "Intensive Promotion"
            },
            {
              title_ar: "منشورات مخصصة",
              title_en: "Personalized Posts"
            },
            {
              title_ar: "40-20 حجز متوقع",
              title_en: "20-40 expected bookings"
            }
          ]
        }
      ]
    }
  },
  computed: {
    currentVenue () {
        return JSON.parse(localStorage.getItem('currentVenue'));
    },
    marketing_credit() {
      return this.$store.state.venue.marketing_credit
    },
    hasBalanceToRequest() {
      return this.marketing_credit > 0;
    }
  },
  created () {
    window.addEventListener('changeLanguage', function(e){
      this.$store.dispatch('loader/loaderOn')
      Promise.all([ 
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    }.bind(this));
    this.get_current_venue_marketing_credit();
    this.$store.dispatch('loader/loaderOff')
  },
  methods: {
    async get_current_venue_marketing_credit() {
      await this.$store.dispatch('venue/getMarketingCreditForCurrentVenue', {venueId: this.currentVenue.id})
    },
    selectPackage(marketing_package) {
      if(marketing_package.price > this.marketing_credit) {
        this.$vs.notify({
            color:'danger',
            text: this.$t('business_marketing.no_enough_balance')
        })
        return;
      }
      this.selectedPackage = marketing_package;
      this.buyConfirmation = true;
    },
    async acceptAlert() {
      let data = {
        agent: this.$store.getters.currentUser.full_name,
        agent_phone_number: this.$store.getters.currentUser.phone_number,
        package_title: this.selectedPackage.title_ar,
        price: this.selectedPackage.price,
        includings: this.selectedPackage.includings,
        venue_id: this.currentVenue.id
      }
      this.$store.dispatch('loader/loaderOn')
      const response = await this.$store.dispatch('venue/addRequestToAirtable', data)
      if(response.status != 200) {
        this.$vs.notify({
            color:'danger',
            icon: 'warning',
            text: response.data.error_message,
            time: 8000
        })
      } else {
        this.$vs.notify({
            color:'success',
            icon: 'favorite',
            text: response.data.message
        })
      }
      this.$store.dispatch('loader/loaderOff')
    },
    rechargeWhatsapp() {
      const url = 'https://wa.me/966504849043?text=مرحبا.%20أرغب%20بإعادة%20شحن%20الرصيد%20الإعلاني%20'
      window.open(url, '_blank');
    }
  },
  watch: {
    
  },

}
</script>

<style scoped>
.is-recommended {
  border: 2px solid #FFB113;
}

</style>
